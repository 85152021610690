<template>
    <div id="financialStrength" class="tw-mt-3" v-if="Object.keys(financialStrength).length > 0 && fetchFinancialExpanded">
            <div class="tabActions tw-flex tw-justify-between tw-items-center">
                <div class="tabContainer">
                    <div class="tabs tw-space-x-2">
                        <div
                            @click="selectedInfo(tab)" v-for="(tab, index) in getCompanyInfoTabs"
                            :key="index"
                            class="tab tw-px-6 tw-text-sm"
                            :style="{
                                borderBottomColor: selected === tab ? 'var(--brand-color)' : '', 
                            }"
                            @mouseover="hoveredOver=true"
                            @mouseleave="hoveredOver=false"
                        >
                            <span :class="{'tw-text-blue-600': selected === tab, 'tw-text-brand': ''}" class="tab-name">
                                {{tab}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='DB Rating'">
                <!-- <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">DB Rating: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.standardRating.rating }}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Rating Override Reasons: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.ratingOverrideReasons && financialStrength.dnbAssessment.standardRating.ratingOverrideReasons.length>0" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-for="rating,index in financialStrength.dnbAssessment.ratingOverrideReasons" :key="index">
                            {{ rating.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Score Date: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{formatDate(financialStrength.dnbAssessment.standardRating.scoreDate) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <!-- <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Financial Strength: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.standardRating.financialStrength }}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Rating Reason: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.ratingReason && financialStrength.dnbAssessment.standardRating.ratingReason.length>0" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-for="rating,index in financialStrength.dnbAssessment.ratingReason" :key="index">
                            {{ rating.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Risk  Segment: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.riskSegment" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.standardRating.riskSegment }}
                    </div>
                    <div v-else>-</div>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('risk-description')">
                                <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Risk Description" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                    </div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Financial Condition: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.financialCondition && financialStrength.dnbAssessment.financialCondition.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.financialCondition.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Has Severe Negative Events: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.hasSevereNegativeEvents" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.hasSevereNegativeEvents }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Is High Risk Business: </div>
                    <div v-if="financialStrength.isHighRiskBusiness" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.isHighRiskBusiness}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2" v-if="financialsInfoLevel.value === 'L3'"> Is Deteriorating Business: </div>
                    <div v-if="financialStrength.isDeterioratingBusiness" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.isDeterioratingBusiness}}
                    </div>
                    <div v-else>-</div>
                </div>

            </div>
            <div v-if="selected ==='Other Rating'">
                <div class="tw-flex tw-mt-2">
                    <strong>Other Rating</strong>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('other-rating')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Other Rating" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Nordic AAA Rating: </div>
                    <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.rating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.nordicAAARating.rating}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Date: </div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{formatDate(financialStrength.nordicAAARating.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Financial Strength: </div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.financialStrength && financialStrength.nordicAAARating.financialStrength.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.nordicAAARating.financialStrength.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Risk Segment: </div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.riskSegment && financialStrength.nordicAAARating.riskSegment.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.nordicAAARating.riskSegment.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> TSR Rating: </div>
                        <div v-if="financialStrength.tsrRating && financialStrength.tsrRating.rating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.tsrRating.rating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Rating Range: </div>
                        <div v-if="financialStrength.tsrRating && financialStrength.tsrRating.ratingRange" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.tsrRating.ratingRange}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Failure'">
                <div class="tw-flex tw-mt-2">
                    <strong>Failure</strong>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('failure-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Failure Description" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.failureScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score Description: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.classScoreDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.failureScore.classScoreDescription}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Date: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{formatDate(financialStrength.dnbAssessment.failureScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Raw Score: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.rawScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.failureScore.rawScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Risk Incidence Percentage: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.riskIncidencePercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.failureScore.riskIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4"> 
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Override Reasons: </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Score Commentary  </div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.scoreCommentary" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.failureScore.scoreCommentary" :key="index">
                                    {{score.description}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Delinquecy'">
                <div class="tw-flex tw-mt-2">
                    <strong>Deliquency</strong>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('deliquency-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Deliquency Description" width="15" height="15" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.delinquencyScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score Description: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.classScoreDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.delinquencyScore.classScoreDescription}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Date: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{formatDate(financialStrength.dnbAssessment.delinquencyScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Raw Score: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.rawScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.delinquencyScore.rawScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Risk Incidence Percentage: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.riskIncidencePercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.delinquencyScore.riskIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4"> 
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Override Reasons: </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Score Commentary  </div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.failureScore.scoreCommentary" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.delinquencyScore.scoreCommentary" :key="index">
                                    {{score.description}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Credit Limit'">
                 <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Assessment Date: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.assessmentDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.assessmentDate}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Recommended Credit Limit: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.value.toLocaleString()}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Currency: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.currency" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.currency}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Average Recommended Credit Limit: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.value.toLocaleString()}}
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.currency}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Comment: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.comment && financialStrength.dnbAssessment.creditLimitRecommendation.comment.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.comment.description}}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Viability'">
                <div class="tw-flex tw-mt-2">
                    <strong>Validity Rating</strong>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('validity-rating')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Validity Description" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="tw-mt-4">
                    <div class="tw-font-medium tw-text-md  tw-mr-2"> Viability Rating: </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Data Depth Assessment: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.dataDepth && financialStrength.viabilityRating.dataDepth.assessment" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.dataDepth.assesment}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Data Depth Indicator: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.dataDepth && financialStrength.viabilityRating.dataDepth.dataDepthIndicator" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.dataDepth.dataDepthIndicator}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Is Financial Data Available: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssesment.viabilityRating.organizationProfile.isFinancialDataAvailable" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.organizationProfile.isFinancialDataAvailable}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Organization Profile Rating: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationProfileRating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.organizationProfile.organizationProfileRating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div class="tw-mt-4">
                    <div class="tw-font-medium tw-text-md tw-mr-2"> Portfolio Comparison Score: </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Bad Rate: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.badRate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.badRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.classScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score Incidence Percentage: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.classScoreIncidencePercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.classScoreIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Model Segment: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment && financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Risk Level: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel && financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Segment Bad Rate: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.segmentBadRate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.segmentBadRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Rating: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.rating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.rating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Rating Date: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.ratingDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{formatDate(financialStrength.dnbAssessment.portfolioComparisonScore.ratingDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Organization Size Assesment: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.assesment" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Organization Size Category: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.organizationSizeCategory" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.organizationSizeCategory}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Trade Data Availability Assesment: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.assesment" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Organization Size Category: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable.organizationSizeCategory" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.isTradeDataAvailable}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Years In Business: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.assesment" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Years In Business Category: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.yearsInBusinessCategory" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.yearsInBusinessCategory}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                 <div class="tw-mt-4">
                    <div class="tw-font-medium tw-text-md tw-mr-2"> Viability  Score: </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Bad Rate: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.badRate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.badRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.classScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score Incidence Percentage: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.classScoreIncidencePercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.classScoreIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score Incidence Percentage: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.overallBadRate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.overallBadRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Risk Level: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.riskLevel && financialStrength.dnbAssessment.viabilityScore.riskLevel.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.riskLevel.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                 </div>


            </div>
            <div v-if="selected ==='Risk Assesment'">
                <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Overall Risk  Assessment: </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.overallRiskAssessment && financialStrength.dnbAssessment.overallRiskAssessment.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.overallRiskAssessment.description}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.overallRiskAssessment && financialStrength.dnbAssessment.overallRiskAssessment.scoreCommentary">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Commentary - Comment Label - Priority: </div>
                            <div  v-for="score,index in financialStrength.dnbAssessment.overallRiskAssessment.scoreCommentary" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{score.comment? score.comment.description:''}} {{ score.commentLabel? score.commentLabel.description:''}} {{score.priority}}
                            </div>
                        </div>
                </div>
            </div>
            <div v-if="selected ==='Lay Off Score'">
                <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Date: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{formatDate(financialStrength.layOffScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                </div>
                 <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Probability: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.probability" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.layOffScore.probability}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> National Risk Percentile: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.nationalRiskPercentile" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.layOffScore.nationalRiskPercentile}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score - Description: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.layOffScore.classScore}} {{financialStrength.layOffScore.classScoreDescription}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2" v-if="financialsInfoLevel.value === 'L3'"> Peers Risk Percentile: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.peersRiskPercentile" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.layOffScore.peersRiskPercentile}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Peers Class Score: </div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.peersClassScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.layOffScore.peersClassScore}} {{ financialStrength.layOffScore.peersClassScoreDescription}}
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Delinquency Norms'">
                <div v-if="financialStrength && financialStrength.delinquencyScoreNorms && financialStrength.delinquencyScoreNorms.length>0">
                    <div v-for="(deliquency,index) in financialStrength.delinquencyScoreNorms" :key="index">
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Calculation Timestamp:</div>
                            <div v-if="deliquency.calculationTimestamp" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{formatDate(deliquency.calculationTimestamp)}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Norms Date: </div>
                            <div v-if="deliquency.industryNorms && deliquency.industryNorms.normsDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{formatDate(deliquency.industryNorms.normsDate)}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  National Percentile Norms : </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{financialStrength.delinquencyScoreNorms.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Raw Score Norms: </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms  && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.rawScoreNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.rawScoreNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Risk Incidence Percentage Norms: </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else> No Data Available </div>
                
            </div>
           <div v-if="selected ==='Failure Norms'">
                <div v-if="financialStrength && financialStrength.failureScoreNorms && financialStrength.failureScoreNorms.length>0">
                    <div v-for="(deliquency,index) in financialStrength.failureScoreNorms" :key="index">
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Calculation Timestamp:</div>
                            <div v-if="deliquency.calculationTimestamp" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{formatDate(deliquency.calculationTimestamp)}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Norms Date: </div>
                            <div v-if="deliquency.industryNorms && deliquency.industryNorms.normsDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{formatDate(deliquency.industryNorms.normsDate)}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  National Percentile Norms : </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Raw Score Norms: </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.rawScoreNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.rawScoreNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="tw-mt-4">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Risk Incidence Percentage Norms: </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Lower Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Median Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.medianScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.medianScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="tw-mt-4 tw-flex">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Upper Quartile Score: </div>
                                <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore}}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else> No Data Available </div>
            </div>
            <div v-if="selected ==='Rating History'">
                <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> History Rating: </div>
                        <div v-if="financialStrength && financialStrength.dnbAssessment && financialStrength.dnbAssessment.historyRating && financialStrength.dnbAssessment.historyRating.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.dnbAssessment.historyRating.description}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> DB Rating - Score Date: </div>
                        <div v-if="financialStrength && financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRatingHistory && financialStrength.dnbAssessment.standardRatingHistory.length>0" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            <div v-for="rating,index in financialStrength.dnbAssessment.standardRatingHistory" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{rating.rating}} {{formatDate(rating.scoreDate)}}
                            </div>
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> TSR Rating History: </div>
                        <div v-if="financialStrength && financialStrength.tsrRatingHistory && financialStrength.tsrRatingHistory.rating" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{financialStrength.tsrRatingHistory.rating}} {{formatDate(financialStrength.tsrRatingHistory.ratingDate)}}
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Emerging Market'">
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Raw Score: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.rawScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.rawScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Class Score: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.classScore" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Date: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{formatDate(financialStrength.dnbAssessment.emergingMarketMediationScore.scoreDate)}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2"> Score Model: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel.description}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex"> 
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">  Score Commentary  </div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreCommentary" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            <div v-for="score,index in financialStrength.dnbAssessment.emergingMarketMediationScore.scoreCommentary" :key="index">
                                {{score.description}}
                            </div>
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <modal name="risk-description">
                <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('risk-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                    <div class="tw-pb-3 tw-font-bold">Risk Description</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">1: </span>Signifies Minimal Risk.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">2:</span> Represents Below Average Risk.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">3:</span> Means Above Average Risk.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">4: </span> Denotes Significant Risk.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">5: </span> Indicates Insufficient Data.</div>
                </div>
            </modal>
            <modal name="other-rating">
                <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('other-rating')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                    <div class="tw-pb-3 tw-font-bold">Other  Rating</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Nordic AAA Rating: </span> Indicates a top-tier credit rating in the Nordic region.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Score Date:</span> Jul 01 2023 Indicates the date when the score was calculated.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Financial Strength:</span> AAA - Reflects the highest level of financial stability.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Risk Segment:</span> 2 – Reflects the level of risk.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">TSR Rating: </span> Indicates a rating assigned by a TSR investigator through a company analysis points system.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Rating Range:</span> 9~0点 A range indicating the Total Shareholder Return rating.</div>
                </div>
            </modal>
            <modal name="failure-description">
                <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('failure-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                    <div class="tw-pb-3 tw-font-bold">Failure  Rating</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Failure Score:</span> A failure score of 90 indicates that a company is less likely to fail, whereas a failure score of 10 suggests that a company is more likely to fail.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Raw Score: </span> number predicting the chance of a risk happening.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Risk Incidence Percentage:</span> The likelihood (as a percentage) that a risk will occur, based on the entity’s score range from historical data analysis.</div>
                </div>
            </modal>
            <modal name="deliquency-description">
                <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('deliquency-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                    <div class="tw-pb-3 tw-font-bold">Deliquency Description</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Raw Score: </span> number predicting the chance of a risk happening.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Risk Incidence Percentage:</span> The likelihood (as a percentage) that a risk will occur, based on the entity’s score range from historical data analysis.</div>
                </div>
            </modal>
             <modal name="validity-rating">
                <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('validity-rating')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                    <div class="tw-pb-3 tw-font-bold">Validity</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">A: </span> Highly established entities (e.g., 5+ years in operation, 50+ employees, $500K+ in sales).</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">B: </span> Moderately established entities.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">X:</span> Newly established or small entities (e.g., <5 years in operation, <10 employees, <$10K in sales).</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Y:</span> Branches.</div>
                    <div class="tw-mt-2"><span class="tw-font-bold">Z:</span> Subsidiaries..</div>
                </div>
            </modal>
    </div>
</template>
<script>
import {DateTime} from "luxon";
export default ({
    name: "financialStrength",
    props: {
        financialStrength: {
            type: Object,
            default: {}
        },
       financialsInfoLevel:{
           type:Object,
           default: {}
       },
       disableFetchMore:{
           type:Boolean,
       },
       fetchFinancialExpanded:{
           type:Boolean,
       }
        
    },
    data(){
        return{
            selected: "DB Rating",
        }
    },
     computed: {
        getCompanyInfoTabs() {
            let tabs = [];
            if(this.financialsInfoLevel.value === "L1") {
                tabs = ["DB Rating","Other Rating","Failure","Delinquecy","Lay Off Score","Rating History"];
                this.selected = "DB Rating"
            } else if(this.financialsInfoLevel.value === "L3" && this.disableFetchMore) {
                tabs = ["DB Rating","Other Rating","Failure","Delinquecy","Credit Limit","Viability","Risk Assesment","Lay Off Score","Delinquency Norms", "Failure Norms", "Rating History", "Emerging Market"];
                this.selected = "DB Rating"
            }
            return tabs
        }
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
         hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
    }
})
</script>
<style scoped>
    .tabActions {
        display: flex;
        overflow-x: scroll;
    }
    /* .scroll-bar{
        height:10px;
    } */
    .tabs {
        width: 100%;
        border-bottom: 3px solid var(--brand-accent-neutral);
        cursor: pointer;
        margin: 0.4rem 0;
        font-family: var(--brand-font);
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 10;
        display:flex;
        
        
    }

    .tab {
        transform: translateY(3px);
        border-bottom: 3px solid transparent;
        transition: var(--transition-mnml);
        border-radius: var(--component-border) var(--component-border) 0 0;
        font-size: 14px;
        text-align: center;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
</style>