<template>
    <div id="companyinfo" class="tw-mt-3" v-if="Object.keys(companyInfo).length > 0 && fetchCompanyExpanded">
        <div class="tabActions tw-flex tw-justify-between tw-items-center">
            <div class="tabContainer">
                <div class="tabs tw-space-x-2">
                    <div
                        @click="selectedInfo(tab)"
                        v-for="(tab, index) in getCompanyInfoTabs"
                        :key="index"
                        class="tab tw-px-6"
                        :style="{
                            borderBottomColor: selected === tab ? 'var(--brand-color)' : '',
                        }"
                        @mouseover="hoveredOver = true"
                        @mouseleave="hoveredOver = false"
                    >
                        <span :class="{'tw-text-blue-600': selected === tab, 'tw-text-brand': ''}" class="tab-name">
                            {{ tab }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Organization'">
            <div class="tw-mt-4 tw-flex" v-if="!dbResellerCorpRecord">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">DUNS NUmber:</div>
                <div v-if="companyInfo.duns" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.duns }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Company Name:</div>
                <div v-if="companyInfo.primaryName" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.primaryName }}
                </div>
                <span v-else>-</span>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registered Name:</div>
                <div v-if="companyInfo.registeredName" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.registeredName }}
                </div>
                <span v-else>-</span>
            </div>

            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Trade Style Name:</div>
                <div v-if="companyInfo.tradeStyleNames.length > 0">
                    <div v-for="(company, index) in companyInfo.tradeStyleNames" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ company.name }}
                    </div>
                </div>
                <span v-else>-</span>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Primary Address:</div>
                <div v-if="companyInfo.primaryAddress" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ companyInfo.primaryAddress.streetAddress.line1 }}{{ companyInfo.primaryAddress.streetAddress.line2 }} {{ companyInfo.primaryAddress.streetAddress.streetNumber }} {{ companyInfo.primaryAddress.postalCode }} {{ companyInfo.primaryAddress.addressLocality.name }} {{ companyInfo.primaryAddress.addressRegion.name }} {{ companyInfo.primaryAddress.addressCountry.name }}</div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registered Address:</div>
                <div v-if="companyInfo.registeredAddress && Object.keys(companyInfo.registeredAddress).length > 0" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.registeredAddress.streetAddress.line1 }}{{ companyInfo.registeredAddress.streetAddress.line2 }} {{ companyInfo.registeredAddress.streetAddress.streetNumber }} {{ companyInfo.registeredAddress.postalCode }}
                    {{ companyInfo.registeredAddress.addressLocality.name }} {{ companyInfo.primaryAddress.addressRegion.name }} {{ companyInfo.primaryAddress.addressCountry.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Mailing Address:</div>
                <div v-if="companyInfo.mailingAddress && Object.keys(companyInfo.mailingAddress).length > 0" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ companyInfo.mailingAddress.streetAddress.line1 }}{{ companyInfo.mailingAddress.streetAddress.line2 }} {{ companyInfo.mailingAddress.streetAddress.streetNumber }} {{ companyInfo.mailingAddress.postalCode }} {{ companyInfo.mailingAddress.addressLocality.name }} {{ companyInfo.mailingAddress.addressRegion.name }} {{ companyInfo.primaryAddress.addressCountry.name }}</div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registered Numbers:</div>
                <div v-if="companyInfo.registrationNumbers.length > 0">
                    <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ num.registrationNumber }} {{ num.typeDescription }}</div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Preferred Registration Number:</div>
                    <div v-if="companyInfo.registrationNumbers.length > 0">
                        <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ num.isPreferredRegistrationNumber }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registration Location:</div>
                <div v-if="companyInfo.registrationNumbers && companyInfo.registrationNumbers.length > 0">
                    <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ num.registrationLocation }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">operations:</div>
                    <div v-if="companyInfo.operations && companyInfo.operations.length > 0">
                        <div v-for="(operate, index) in companyInfo.operations" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ operate.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Character Type:</div>
                <div v-if="companyInfo.charterType" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.charterType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Subject Comments:</div>
                <div v-if="companyInfo.subjectComments && companyInfo.subjectComments.length > 0">
                    <div v-for="(comments, index) in companyInfo.subjectComments" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-for="(comment, index) in comments.commentaries" :key="index">{{ comment.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Comment Date:</div>
                <div v-if="companyInfo.subjectComments && companyInfo.subjectComments.length > 0">
                    <div v-for="(comments, index) in companyInfo.subjectComments" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ formatDate(comments.commentDate) }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Country Code:</div>
                <div v-if="companyInfo.countryISOAlpha2Code" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.countryISOAlpha2Code }}
                </div>
                <div v-else>-</div>
            </div>

            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Operating Status:</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingStatus && companyInfo.dunsControlStatus.operatingStatus.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.operatingStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Operating Start Date:</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingStatus && companyInfo.dunsControlStatus.operatingStatus.startDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.operatingStatus.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Operating Sub Status:</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingSubStatus && companyInfo.dunsControlStatus.operatingSubStatus.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.operatingSubStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Operating Sub Start Date:</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingSubStatus && companyInfo.dunsControlStatus.operatingSubStatus.startDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.operatingSubStatus.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Detailed Operating Status:</div>
                <div v-if="companyInfo.dunsControlStatus.detailedOperatingStatus && companyInfo.dunsControlStatus.detailedOperatingStatus.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.detailedOperatingStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Franchise Operation Type:</div>
                <div v-if="companyInfo && companyInfo.franchiseOperationType && companyInfo.franchiseOperationType.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.franchiseOperationType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Telephone Number:</div>
                <div v-if="companyInfo.telephone.length > 0">
                    <div v-for="(phone, index) in companyInfo.telephone" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ phone.telephoneNumber }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Email:</div>
                <div v-if="companyInfo.email.length > 0">
                    <div v-for="(mail, index) in companyInfo.email" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ mail.address }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Certified Email:</div>
                <div v-if="companyInfo.certifiedEmail" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.certifiedEmail }}
                </div>
                <span v-else>-</span>
            </div>

            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">URL:</div>
                <div v-if="companyInfo.websiteAddress.length > 0">
                    <div v-for="(site, index) in companyInfo.websiteAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ site.url }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domain:</div>
                <div v-if="companyInfo.websiteAddress.length > 0">
                    <div v-for="(site, index) in companyInfo.websiteAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ site.domain }}
                    </div>
                </div>
                <span v-else>-</span>
            </div>
            <div v-if="companyInfo && companyInfo.stockExchages">
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Stock Exchange:</div>
                    <div v-if="companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ stocks.tickerName }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Exchange Name:</div>
                    <div v-if="companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ stocks.exchangeName.description }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Exchange Country:</div>
                    <div v-if="companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ stocks.exchangeCountry.isoAlpha2Code }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Primary:</div>
                    <div v-if="companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ stocks.isPrimary }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Standardized Stock Exhanges:</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ stocks.tickerName }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Listing Date:</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ formatDate(stocks.listingDate) }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Exchange Name:</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ stocks.exchangeName.description }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex" v-if="companyInfo && companyInfo.standardizedStockExchanges">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Section Names:</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index">
                        <div v-if="name.name">
                            <div v-for="(names, index) in stocks.sectionNames" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ names.name }}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Language:</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index">
                        <div v-for="(names, index) in stocks.sectionNames" :key="index">
                            <div v-if="names.name" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ names.language.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Currency:</div>
                <div v-if="companyInfo.defaultCurrency" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.defaultCurrency }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Prefrerred Language:</div>
                <div v-if="companyInfo.preferredLanguage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.preferredLanguage.description }}
                </div>
                <span v-else>-</span>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Third Party Assessment:</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.description">
                            {{ assesment.description }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Assessment Date:</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.assessmentDate">
                            {{ formatDate(assesment.assessmentDate) }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Value:</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.assessmentDate">
                            {{ assesment.Value }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">statistical Area:</div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">CBSA Name:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.cbsaName }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">CBSA Code:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.cbsaCode }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Economic Area Of InfluenceCode:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.economicAreaOfInfluenceCode }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Population Rank:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0 && Object.keys(companyInfo.primaryAddress.statisticalArea.populationRank).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.populationRank.rankNumber }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Rank Number:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0 && Object.keys(companyInfo.primaryAddress.statisticalArea.populationRank).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.populationRank.rankDescription }}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="tw-flex tw-mt-5">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Location Ownership:</div>
                <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.locationOwnership && Object.keys(companyInfo.primaryAddress.locationOwnership).length > 0">
                    {{ companyInfo.primaryAddress.locationOwnership.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">statistical Area:</div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">CBSA Name:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.cbsaName }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">CBSA Code:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.cbsaCode }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Economic Area Of InfluenceCode:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.economicAreaOfInfluenceCode }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex tw-mt-2">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Population Rank:</div>
                    <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0 && companyInfo.primaryAddress.statisticalArea.populationRank && Object.keys(companyInfo.primaryAddress.statisticalArea.populationRank).length > 0">
                        {{ companyInfo.primaryAddress.statisticalArea.populationRank.rankNumber }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Premises Area:</div>
                    <div class="tw-flex tw-mt-2">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Measurement:</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.premisesArea && Object.keys(companyInfo.primaryAddress.premisesArea).length > 0">{{ companyInfo.primaryAddress.premisesArea.measurement }} {{ companyInfo.primaryAddress.premisesArea.unitDescription }}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-flex tw-mt-2">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Reliability:</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.premisesArea && Object.keys(companyInfo.primaryAddress.premisesArea).length > 0">{{ companyInfo.primaryAddress.premisesArea.measurement }} {{ companyInfo.primaryAddress.premisesArea.reliabilityDescription }}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Standard Address Codes:</div>
                        <div class="tw-flex tw-mt-2">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Manufacturing Location:</div>
                            <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                {{ companyInfo.primaryAddress.standardAddressCodes.isManufacturingLocation }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-flex tw-mt-2">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Registered Address:</div>
                            <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                {{ companyInfo.primaryAddress.standardAddressCodes.isRegisteredAddress }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-flex tw-mt-2">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Residential Address:</div>
                            <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                {{ companyInfo.primaryAddress.standardAddressCodes.isResidentialAddress }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>

                    <div class="tw-flex tw-mt-2">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Congressional Districts:</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.congressionalDistricts && companyInfo.primaryAddress.congressionalDistricts.length > 0">
                            <div v-for="(distict, index) in congressionalDistricts" :key="index">
                                {{ district.district }}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="tw-mt-4">
                        <div class="tw-flex tw-flex-col tw-mt-1">
                            <div class="tw-overflow-x-auto scroll">
                                <div class="tw-align-middle tw-inline-block tw-min-w-full tw-overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryNames ? 'print-overflow-table' : ''">
                                    <div class="tw-shadow tw-rounded tw-border tw-border-solid tw-border-gray-300">
                                        <table id="formerPrimaryNames" class="formerPrimaryNamesTable tw-min-w-full tw-divide-y tw-divide-gray-200">
                                            <thead class="tw-bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Former Primary Names
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Start Date
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        End Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerPrimaryNames && companyInfo.formerPrimaryNames.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryNames" :key="index">
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.name }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center" colspan="3">
                                                        No data available
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mt-4">
                        <div class="tw-flex tw-flex-col tw-mt-1">
                            <div class="tw-overflow-x-auto scroll">
                                <div class="tw-align-middle tw-inline-block tw-min-w-full tw-overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryNames && companyInfo.formerPrimaryNames.length ? 'print-overflow-table' : ''">
                                    <div class="tw-shadow tw-rounded tw-border tw-border-solid tw-border-gray-300">
                                        <table id="formerRegisteredNames" class="formerRegisteredNamesTable tw-min-w-full tw-divide-y tw-divide-gray-200">
                                            <thead class="tw-bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Former Registered Names
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Start Date
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        End Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerRegisteredNames && companyInfo.formerRegisteredNames.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryNames" :key="index">
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.name }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center" colspan="3">
                                                        No data available
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mt-4">
                        <div class="tw-flex tw-flex-col tw-mt-1">
                            <div class="tw-overflow-x-auto scroll">
                                <div class="tw-align-middle tw-inline-block tw-min-w-full tw-overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerRegisteredAddresses && companyInfo.formerRegisteredAddresses.length ? 'print-overflow-table' : ''">
                                    <div class="tw-shadow tw-rounded tw-border tw-border-solid tw-border-gray-300">
                                        <table id="formerRegisteredAddresses" class="formerRegisteredAddressesTable tw-min-w-full tw-divide-y tw-divide-gray-200">
                                            <thead class="tw-bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Former Registered Address
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Start Date
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        End Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerRegisteredAddresses && companyInfo.formerRegisteredAddresses.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerRegisteredAddresses" :key="index">
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">{{ name.streetName ? name.streetName + "," : "" }} {{ name.postalCode ? name.postalCode + "," : "" }} {{ name.addressLocality ? name.addressLocality.name + "," : "" }} {{ name.addressCountry.name }}</td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center" colspan="3">
                                                        No data available
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mt-4">
                        <div class="tw-flex tw-flex-col tw-mt-1">
                            <div class="tw-overflow-x-auto scroll">
                                <div class="tw-align-middle tw-inline-block tw-min-w-full tw-overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryAddresses && companyInfo.formerPrimaryAddresses.length ? 'print-overflow-table' : ''">
                                    <div class="tw-shadow tw-rounded tw-border tw-border-solid tw-border-gray-300">
                                        <table id="formerPrimaryAddresses" class="formerPrimaryAddressesTable tw-min-w-full tw-divide-y tw-divide-gray-200">
                                            <thead class="tw-bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Former Primary Address
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        Start Date
                                                    </th>
                                                    <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                        End Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerPrimaryAddresses && companyInfo.formerPrimaryAddresses.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryAddresses" :key="index">
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">{{ name.streetName ? name.streetName + "," : "" }} {{ name.postalCode ? name.postalCode + "," : "" }} {{ name.addressLocality ? name.addressLocality.name + "," : "" }} {{ name.addressCountry.name }}</td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center" colspan="3">
                                                        No data available
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Legal'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Start Date:</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.legalForm.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Incorprated Date:</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.incorporatedDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Business Entity Type:</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.businessEntityType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Control Ownership Date:</div>
                <div v-if="companyInfo && companyInfo.controlOwnershipDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.controlOwnershipDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Control Ownership Type:</div>
                <div v-if="companyInfo && companyInfo.controlOwnershipType && companyInfo.controlOwnershipType.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.controlOwnershipType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Legal Form:</div>
                <div v-if="companyInfo && companyInfo.legalForm && companyInfo.legalForm.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.legalForm.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registered Legal Form:</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.registeredDetails.legalForm.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registration Location:</div>
                <div v-if="companyInfo && companyInfo.legalForm && companyInfo.legalForm.registrationLocation && companyInfo.legalForm.registrationLocation.addressRegion" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.legalForm.registrationLocation.addressRegion }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registration Status:</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.registrationStatus && companyInfo.registeredDetails.legalForm.registrationStatus.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.registeredDetails.legalForm.registrationStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Local Legal Form:</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.localLegalForms.length > 0" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    <div v-for="(local, index) in companyInfo.registeredDetails.legalForm.localLegalForms" :key="index">
                        {{ local.description }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Stand Alone:</div>
                <div v-if="companyInfo && companyInfo.isStandalone" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Preferred Language:</div>
                <div v-if="companyInfo && companyInfo.preferredLanguage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.preferredLanguage.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Currency:</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.defaultCurrency }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Indicators'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Agent:</div>
                <div v-if="companyInfo && companyInfo.isAgent" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isAgent }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Importer:</div>
                <div v-if="companyInfo && companyInfo.isAgent" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isImporter }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Exporter:</div>
                <div v-if="companyInfo && companyInfo.isAgent" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isExporter }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Marketable:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.isMarketable }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Mail Undeliiverable:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.isMailUndeliverable }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Telephone Disconnected:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.isTelephoneDisconnected" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.isTelephoneDisconnected }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Delisted:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.isDelisted }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Subject Handlig Details :</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.subjectHandlingDetails.length > 0">
                    <div v-for="(subject, index) in companyInfo.dunsControlStatus.subjectHandlingDetails" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="subject.description">{{ subject.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Fortune 1000 Listed:</div>
                <div v-if="companyInfo && companyInfo.isFortune1000Listed" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isFortune1000Listed }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Forbes Largest Private Companies Listed:</div>
                <div v-if="companyInfo && companyInfo.isForbesLargestPrivateCompaniesListed" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isForbesLargestPrivateCompaniesListed }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">First Report Date:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.firstReportDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.firstReportDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Record Class:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.recordClass" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.recordClass.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Self Requested DUNS:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.isSelfRequestedDUNS" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.dunsControlStatus.isSelfRequestedDUNS }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Self Request Date:</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.selfRequestDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.selfRequestDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Small Business:</div>
                <div v-if="companyInfo && companyInfo.isSmallBusiness" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isSmallBusiness }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Standalone:</div>
                <div v-if="companyInfo && companyInfo.isStandalone" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Industry'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Activities:</div>
                <div v-if="companyInfo && companyInfo.activities">
                    <div v-for="(activity, index) in companyInfo.activities" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="activity.description">{{ activity.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Primary Industry Code:</div>
                <div v-if="companyInfo && companyInfo.primaryIndustryCode" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ companyInfo.primaryIndustryCode.usSicV4 }} - {{ companyInfo.primaryIndustryCode.usSicV4Description }}</div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Industry Code:</div>
                <div v-if="companyInfo && companyInfo.industryCodes">
                    <div v-for="(code, index) in companyInfo.industryCodes" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="code.description">{{ code.code }} - {{ code.description }} ({{ code.typeDescription }})</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">UNSPSC Code:</div>
                <div v-if="companyInfo && companyInfo.unspscCodes">
                    <div v-for="(code, index) in companyInfo.unspscCodes" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="code.description">{{ code.code }} - {{ code.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Non Classified Establishment:</div>
                <div v-if="companyInfo && companyInfo.isNonClassifiedEstablishment" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.isNonClassifiedEstablishment }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Employees'">
            <div class="tw-mt-4" v-for="(employee, index) in companyInfo.numberOfEmployees" :key="index">
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Number Of Employees:</div>
                    <div v-if="employee.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ employee.value }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Employee Figures Date:</div>
                    <div v-if="employee.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ formatDate(employee.employeeFiguresDate) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Reliability:</div>
                    <div v-if="employee.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ employee.reliabilityDescription }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Employee Categories:</div>
                    <div v-if="employee.employeeCategories.length > 0">
                        <div v-for="(employee, index) in employee.employeeCategories" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ employee.employmentBasisDescription }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div>
                <div class="tw-flex tw-mt-4">
                    <strong> Trend</strong>
                    <div class="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center" @click="showModal('trend-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="Trend Description" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="tw-mt-4" v-for="(employee, index) in companyInfo.numberOfEmployees" :key="index">
                    <div class="tw-mt-4" v-for="(trend, index) in employee.trend" :key="index">
                        <div class="tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Time Period:</div>
                            <div v-if="trend.timePeriod && trend.timePeriod.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ trend.timePeriod.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Growth Rate</div>
                            <div v-if="trend.growthRate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ trend.growthRate }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">value</div>
                            <div v-if="trend.timePeriod && trend.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ trend.value }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Reliability</div>
                            <div v-if="trend.timePeriod && trend.reliabilityDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ trend.reliabilityDescription }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-mt-4">
                <strong> Global Ultimate </strong>
                <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                    <div class="tw-mt-4 tw-flex">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.numberOfEmployees.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.globalUltimate.numberOfEmployees" :key="index">
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Number Of Employees:</div>
                                    <div v-if="details.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.value }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Minimum Value:</div>
                                    <div v-if="details.minimumValue" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.minimumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Maximum Value:</div>
                                    <div v-if="details.maximumValue" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.maximumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Employee Figures Date:</div>
                                    <div v-if="details.employeeFiguresDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ formatDate(details.employeeFiguresDate) }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <strong> Domestic Ultimate </strong>
                <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                    <div class="tw-mt-4 tw-flex">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.numberOfEmployees.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.domesticUltimate.numberOfEmployees" :key="index">
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Number Of Employees:</div>
                                    <div v-if="details.value" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.value }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Minimum Value:</div>
                                    <div v-if="details.minimumValue" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.minimumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Maximum Value:</div>
                                    <div v-if="details.maximumValue" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ details.maximumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="tw-mt-4 tw-flex">
                                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Employee Figures Date:</div>
                                    <div v-if="details.employeeFiguresDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                        {{ formatDate(details.employeeFiguresDate) }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4">
                <strong> Employer Designation </strong>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Score Date</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.employerDesignation.scoreDate }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Index Score</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.employerDesignation.indexScore }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Designaion</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.designation && companyInfo.employerDesignation.designation.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.employerDesignation.designation.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Reliability</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.reliability && companyInfo.employerDesignation.reliability.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.employerDesignation.reliability.description }}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Financials'">
            <div class="tw-mt-4">
                <div v-if="companyInfo && companyInfo.financials" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    <div v-for="(financial, index) in companyInfo.financials" :key="index">
                        <div v-if="financial.yearlyRevenue">
                            <div class="tw-mt-4 tw-flex" v-for="(revenue, index) in financial.yearlyRevenue" :key="index">
                                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Yearly Revenue:</div>
                                <div v-if="revenue.value" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ revenue.value.toLocaleString() }} {{ revenue.currency }}</div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Unit Code:</div>
                            <div v-if="financial.unitCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ financial.unitCode }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement to Date:</div>
                            <div v-if="financial.financialStatementToDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ formatDate(financial.financialStatementToDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Scope:</div>
                            <div v-if="financial.informationScopeDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ financial.informationScopeDescription }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Fiscal Year End:</div>
                    <div v-if="companyInfo.fiscalYearEnd" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.fiscalYearEnd }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate - Revenue:</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div class="tw-mt-4 tw-flex">
                                    <div v-if="details.yearlyRevenue">
                                        <div v-for="(revenue, index) in details.yearlyRevenue" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ revenue.value }} {{ revenue.currency }}</div>
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Scope:</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{ details.informationScopeDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Reliability:</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{ details.reliabilityDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate - Revenue:</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div class="tw-mt-4 tw-flex">
                                    <div v-if="details.yearlyRevenue">
                                        <div v-for="(revenue, index) in details.yearlyRevenue" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ revenue.value.toLocaleString() }} {{ revenue.currentcy }}</div>
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Scope:</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{ details.informationScopeDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Statement Reliability:</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="tw-mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                    {{ details.reliabilityDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Banks</div>
                <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                    <div v-for="(details, index) in companyInfo.banks" :key="index">
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Name:</div>
                            <div v-if="detail.name" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.name }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Branch Name:</div>
                            <div v-if="detail.name" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.branchName }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Telephone Number:</div>
                            <div v-if="details.telephone && details.telephone.length > 0">
                                <div v-for="(number, index) in details.telephone" :key="index">{{ number.telephoneNumber }}</div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Address:</div>
                            <div v-if="detail.streetAddress && detail.streetAddress.line1" class="tw-text-gray-500 tw-no-underline tw-break-all">{{ detail.streetAddress.line1 }} {{ detail.streetAddress.line2 }}</div>
                            <div v-else-if="detail.addressLocality && detail.addressLocality.name" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.addressLocality.name }}
                            </div>
                            <div v-else-if="detail.postalCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.postalCode }}
                            </div>
                            <div v-else-if="detail.addressRegion" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.addressRegion.name }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Current Notable Event:</div>
                            <div v-if="detail.currentNotableEvent && detail.currentNotableEvent.eventType && detail.currentNotableEvent.eventType.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ detail.currentNotableEvent.eventType.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registration Number:</div>
                            <div v-if="details.registrationNumbers && details.registrationNumbers.length > 0">
                                <div v-for="(number, index) in details.registrationNumbers" :key="index">{{ number.registrationNumber }}</div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Start Date:</div>
                            <div v-if="detail.currentNotableEvent && detail.currentNotableEvent.startDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ formatDate(detail.currentNotableEvent.startDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Last Update:</div>
                            <div v-if="detail.currentNotableEvent && detail.currentNotableEvent.lastUpdateDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ formatDate(detail.currentNotableEvent.lastUpdateDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Business Trust Index</div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Score Date</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.scoreDate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ formatDate(companyInfo.businessTrustIndex.scoreDate) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Trust Index</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.trustIndex" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.trustIndex }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Trust Class</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.trustClass" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.trustClass.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">National Percentile</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.nationalPercentile" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.nationalPercentile }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Data Provider</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.dataProvider" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.dataProvider.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Score Reasons</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.scoreReasons">
                        <div v-for="(reason, index) in companyInfo.businessTrustIndex.scoreReasons" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ reason.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="tw-mt-4">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Excluded Organization</div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Duns</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.excludedOrganization" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.excludedOrganization.duns }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Name</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.excludedOrganization" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.excludedOrganization.name }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Address</div>
                    <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.address && companyInfo.businessTrustIndex.address.streetAddressLines" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.businessTrustIndex.address.streetAddressLines.line1 }}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Regulations'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Regulator Name:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.name" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.regulations.regulatorDetails.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Regulator ID:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.regulatorID" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.regulations.regulatorDetails.regulatorID }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Region:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.address.addressRegion" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.regulations.regulatorDetails.address.addressRegion }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registration Number:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.registrationNumber" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.regulations.regulatorDetails.registrationNumber }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Registry URL:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.registryURL" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.regulations.regulatorDetails.registryURL }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Candidates:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.matchCandidates && companyInfo.regulations.matchCandidates.length > 0">
                    <div v-for="(candidate, index) in companyInfo.regulations.matchCandidates" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ candidate.name }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Address:</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.matchCandidates && companyInfo.regulations.matchCandidates.length > 0">
                    <div v-for="(candidate, index) in companyInfo.regulations.matchCandidates.length > 0" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ candidate.address.streetAddress.line1 }} {{ candidate.address.streetAddress.line2 }} {{ candidate.address.addressLocality.name }} {{ candidate.address.addressCountry.name }}
                        {{ candidate.address.addressCountry.postalCode }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Assignment Model'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Is Standalone:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.familytreeRolesPlayed && companyInfo.assignmentModel.familytreeRolesPlayed.length > 0">
                    <div v-for="(family, index) in companyInfo.assignmentModel.familytreeRolesPlayed" :key="index">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Family Tree Role:</div>
                        <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ family.description }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Employee Reliability:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.employeeReliability && companyInfo.assignmentModel.employeeReliability.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.employeeReliability.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Sales Reliability:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.salesReliability && companyInfo.assignmentModel.salesReliability.description" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.salesReliability.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Has Change In GlobalUltimate:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.hasChangeInGlobalUltimate" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.hasChangeInGlobalUltimate }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Physical Location - Employee Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.physicalLocation.employeeCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.physicalLocation.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Physical Location - Sales Amount:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.physicalLocation.salesAmount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.physicalLocation.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Linked Companies - Employee Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.linkedCompanies.employeeCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Linked Companies - Sales Amount:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.linkedCompanies.salesAmount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Country Group - Employee Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.countryGroup.employeeCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Country Group - Sales Amount:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.countryGroup.salesAmount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate - Employee Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.employeeCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Sales Amount:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.salesAmount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Family Tree Members Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.familyTreeMembersCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.familyTreeMembersCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Industry Sectors Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.industrySectorsCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.industrySectorsCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Primary Sector - Industry Code:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.industryCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Primary Sector - Industry Description:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.industryDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Primary Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Secondary Sector - Industry Code:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.industryCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Secondary Sector - Industry Description:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.industryDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Secondary Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Global Ultimate- Unclassified Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.unclassifiedSector && companyInfo.assignmentModel.globalUltimate.unclassifiedSector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.unclassifiedSector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate - Employee Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.employeeCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Sales Amount:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.salesAmount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Family Tree Members Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.familyTreeMembersCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.familyTreeMembersCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Industry Sectors Count:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.industrySectorsCount" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.industrySectorsCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Primary Sector - Industry Code:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.industryCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Primary Sector - Industry Description:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.industryDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Primary Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Secondary Sector - Industry Code:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.industryCode" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Secondary Sector - Industry Description:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.industryDescription" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Secondary Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Domestic Ultimate- Unclassified Sector - Sector Percentage:</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.unclassifiedSector && companyInfo.assignmentModel.domesticUltimate.unclassifiedSector.sectorPercentage" class="tw-text-gray-500 tw-no-underline tw-break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.unclassifiedSector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Competitors'">
            <div class="tw-mt-4">
                <div v-if="companyInfo && companyInfo.competitors && companyInfo.competitors.length > 0">
                    <div v-for="(info, index) in companyInfo.competitors" :key="index">
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">DUNS:</div>
                            <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ info.duns }}
                            </div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Primary Name:</div>
                            <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ info.primaryName }}
                            </div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Individual Employee Count:</div>
                            <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ info.individualEmployeeCount }}
                            </div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Sales Revenue:</div>
                            <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ info.salesRevenue }}
                            </div>
                        </div>
                        <div class="tw-mt-4 tw-flex">
                            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Issued Share Capital Amount:</div>
                            <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                                {{ info.issuedShareCapitalAmount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="companyInfo && companyInfo.otherCompetitors && companyInfo.otherCompetitors.length > 0">
                <div v-for="(info, index) in companyInfo.otherCompetitors" :key="index">
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Other Competitors:</div>
                        <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ info.duns }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="companyInfo && companyInfo.competitors && (!companyInfo.competitors || (companyInfo.competitors && companyInfo.competitors.length === 0)) && companyInfo && companyInfo.otherCompetitors && companyInfo.otherCompetitors.length === 0" class="tw-px-6 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
                No data available
            </div>
        </div>
        <div v-if="selected === 'Summary'">
            <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Summary:</div>
            <div v-if="companyInfo && companyInfo.summary && companyInfo.summary.length > 0">
                <div v-for="(info, index) in companyInfo.summary" :key="index">
                    <div class="tw-mt-4 tw-flex">
                        <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                            {{ info.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Multilingual'">
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Primary Name- Language - Writing Script:</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryName && companyInfo.multilingualPrimaryName.length > 0">
                    <div v-for="(name, index) in companyInfo.multilingualPrimaryName" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} {{ name.language.description }} {{ name.writingScript.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Registered Names- Language - Writing Script:</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredNames && companyInfo.multilingualRegisteredNames.length > 0">
                    <div v-for="(name, index) in companyInfo.multilingualRegisteredNames" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} {{ name.language.description }} {{ name.writingScript.description }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Trade style Names - Language - Priority:</div>
                <div v-if="companyInfo && companyInfo.multilingualTradestyleNames">
                    <div v-for="(name, index) in companyInfo.multilingualTradestyleNames" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} - {{ name.language.description }} - {{ name.priority }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Primary Address :</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.streetAddress.line1 || address.streetAddress.line2 || address.addressLocality.name || address.postalCode || address.addressRegion.name || address.addressCountry.name || address.continentalRegion.name">
                            {{ address.streetAddress.line1 }} {{ address.streetAddress.line2 }} {{ address.addressLocality.name }} {{ address.postalCode }} {{ address.addressRegion.name }} {{ address.addressCountry.name }} {{ address.continentalRegion && address.continentalRegion.name ? address.continentalRegion.name : "" }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">language :</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.language">
                            {{ address.language }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Writing Script:</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.writingScript">
                            {{ address.writingScript }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Registered Address :</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.streetAddress.line1 || address.streetAddress.line2 || address.addressLocality.name || address.postalCode || address.addressRegion.name || address.addressCountry.name || address.continentalRegion.name">
                            {{ address.streetAddress.line1 }} {{ address.streetAddress.line2 }} {{ address.addressLocality.name }} {{ address.postalCode }} {{ address.addressRegion.name }} {{ address.addressCountry.name }} {{ address.continentalRegion && address.continentalRegion.name ? address.continentalRegion.name : "" }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">language :</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.language">
                            {{ address.language }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Writing Script:</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="address.writingScript">
                            {{ address.writingScript }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Imperial Calendar Start Year:</div>
                <div v-if="companyInfo && companyInfo.imperialCalendarStartYear">
                    <div class="tw-text-gray-500 tw-no-underline tw-break-all">
                        {{ companyInfo.imperialCalendarStartYear }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Multilingual Search Names-language-Writing Script:</div>
                <div v-if="companyInfo && companyInfo.multiLingualSearchNames">
                    <div v-for="(names, index) in companyInfo.multiLingualSearchNames" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div>{{ name.name }} - {{ name.language.description }} - {{ name.writingScript.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">TSR Commodity Codes - language - writingscript:</div>
                <div v-if="companyInfo && companyInfo.tsrCommodityCodes">
                    <div v-for="(tsr, index) in companyInfo.tsrCommodityCodes" :key="index" class="tw-text-gray-500 tw-no-underline tw-break-all">
                        <div v-if="tsr.commodityName">
                            {{ tsr.commodityName }}
                        </div>
                        <div v-if="tsr.language && tsr.language.description">
                            {{ tsr.language.description }}
                        </div>
                        <div v-if="tsr.writingScript && tsr.writingScript.description">
                            {{ tsr.writingScript.description }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">Investigation Date:</div>
                <div v-if="companyInfo && companyInfo.investigationDate">
                    {{ formatDate(companyInfo.investigationDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="tw-mt-4 tw-flex">
                <div class="tw-font-medium tw-text-sm tw-text-gray-400 tw-mr-2">TSR Report Date:</div>
                <div v-if="companyInfo && companyInfo.tsrReportDate">
                    {{ formatDate(tsr.tsrReportDate) }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <modal name="trend-description">
            <div class="tw-flex tw-mt-2  tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('trend-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>
            <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                <div class="tw-pb-3 tw-font-bold">Trend Description</div>
                <div class="tw-mt-2"><span class="tw-font-bold">Time Period: </span>The specific timeframe during which the employee data was recorded.</div>
                <div class="tw-mt-2"><span class="tw-font-bold">Growth Rate: </span> percentage change in the number of employees from the base year to the trend year.</div>
                <div class="tw-mt-2"><span class="tw-font-bold">Value:</span> The total number of employees engaged by an entity for its business operations, including various figures such as consolidated, individual entity, or headquarters only.</div>
                <div class="tw-mt-2"><span class="tw-font-bold">Reliability: </span> An assessment of the integrity and accuracy of the presented figures.</div>
            </div>
        </modal>
    </div>
</template>
<script>
import {DateTime} from "luxon";
import {mapGetters} from "vuex";
export default {
    name: "companyInfo",
    props: {
        companyInfo: {
            type: Object,
            default: {},
        },
        companyInfoLevel: {
            type: Object,
        },
        disableFetchMore: {
            type: Boolean,
        },
        fetchCompanyExpanded: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected: "Organization",
        };
    },
    computed: {
        ...mapGetters(["getPermissions"]),
        getCompanyInfoTabs() {
            let tabs = [];
            if (this.companyInfoLevel.value === "L1") {
                tabs = ["Organization", "Indicators", "Industry", "Multilingual"];
                this.selected = "Organization";
            } else if (this.companyInfoLevel.value === "L3" && this.disableFetchMore) {
                tabs = ["Organization", "Legal", "Indicators", "Industry", "Employees", "Financials", "Regulations", "Assignment Model", "Competitors", "Summary", "Multilingual"];
                this.selected = "Organization";
            }
            return tabs;
        },
        dbResellerCorpRecord() {
            return this.getPermissions.includes("dnb_reseller__corp_record");
        },
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
    },
};
</script>
<style scoped>
.tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
}
.scroll-bar {
    height: 10px;
}
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}

.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
